@import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat:wght@300;400;500;600;700&family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

cards{

    align-items: center;
        justify-content: center;
        width: 400px;
        height: 400px;
        margin-bottom : 20px;
        margin-left: 80px;
        border: 2px solid black;
        
    
    }
    .cards-title{
        margin-left: 80px;
    }
    
    .cards-text{
        
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        padding: 1rem;
        top: 0;
        font-weight: lighter;
        margin-left: 130px;
        font-family: "Lato" ,sans-serif;
    }
    .card-link{
        text-decoration: none;
        margin-left: 50px;
    
    
    }
    
    .homeimg{
        display: fixed;
        width: 50%;
        height: auto;
        justify-content: center;
        align-items: center;
        margin-left: 100px;
    
    }

    @media only screen and (max-width: 768px) {
       
       
       
        .cards {
            height: auto;
            align-items: center;
            justify-content: center;
            margin: 0px;
            
          }
           
          .cards-title{
            /* justify-content: center;
            align-items: center; */
            margin-right: 50px;
        }
          .cards-text{
            
            margin: 0px;
            align-items: center;
            justify-content: center;
            font-weight: lighter;
            font-family: "Lato" ,sans-serif;
        }

        .card-link {
          font-size: 15px;
          text-decoration: none;
        }

        .homeimg{
            justify-content: center;
            align-items: center;
            margin: 0;
            width: 100%;
        }

       
      }      