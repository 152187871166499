
  h1 {
    
        font-size: 50px;
        font-weight: lighter;
        align-items: center;
        justify-content: center;
         margin-left: 60px; 
        
}
h4{
    font-size: 30px;
    font-weight: lighter;
    align-items: center;
    margin-left: 60px;

}


.cardm a{
    display: flexbox;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 0 3px;
    color: green;
    font-weight: 600;  
    margin-top: 10px;

}
.cardm{
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 150px;
    max-width: 400px;
    margin-bottom : 20px;
    margin-left: 60px;
    border: 1.5px solid rgb(153, 223, 47);
    padding: 0 30px;
    box-sizing: border-box;
    display: inline-block;
    overflow-y: hidden;
    
}

@media only screen and (max-width: 768px) {
  
  .cardm {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 20%;
      width: 70%;
      }
  
    .cardm a {
      width: 10%;
      font-size: 10px;
    }
    h1{
      justify-content: center;
      align-items: center;
      margin-right: 5%;
    }
    h4{
      justify-content: center;
      align-items: center;
      margin-right: 5%;
    }
  }      