.footer-container{
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
}
.foot{
    align-items: center;
    justify-content: center;
    margin-top: 10%;
    margin-left: 30px;
}
.footer{
    width: 100%;
    padding: 6rem 0;
    
}