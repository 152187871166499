
.perfect{
    font-size: 40px;
    font-weight: lighter;
    align-items: center;
     margin-left: 100px; 
     
}
 h3{
    font-weight: lighter;
}

/* h5{

} */

.cardf {
   
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 50px;
  }
  
  .cardf-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 40%;
  }
  .cardf {
    position: relative;
  }
  
  
  
  .cardf-image {
    max-width: 100%;
    height: auto;
    float: left;
  }

   
.cardf-content {

    padding: 20px;
   
  }
     
.cardz-content {

  padding: 20px;
  
}
  .cardf-title {
    margin-top: 10px;
  }
  .cardff-title {
    margin-top: 10px;
  }
  
  .cardf-description {
    margin-bottom: 0;
        text-align: initial;
   
  }
.cardfffdescription{
  text-align: initial;
  
}
  .cardd-content{
    display: block;
 
    border: 1px solid #ccc;
    margin-bottom: 2%;
  }

.row h5{
  text-align: justify;
 padding: 5px 20%;
}
  /* .cardff-title{
    clear: left; 
     margin-left: 530px;
  } */

  /* .cardff-substep{
    display: flex;
    align-items: flex-start;
  } */

  .cardff-substep-content{
    padding: 20px;
  
    flex-basis: 60%;
  }



  .cardc{
    border: 1px solid #ccc;
    border-radius: 8px;

  }
  .cardc-content{
    display: flex;
    flex-direction: column;
    align-items: center;
  
  }
  .cardc-body{
    display: flex;
    flex-direction: row;
    /* align-items: center;
    justify-content: center; */
  }
  .cardc-image-container{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
  }

  .cardc-image-container img{
    width: 50%;
    height: auto;
  }

  .row1{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .column1{
    flex: 0 0 50%;
    padding: 10px;
  }

  .column1 img{
      width: 100%;
      height: auto;
    }

    .carde {
      display: flex;
      flex-direction: column;
      align-items: initial;
      border: 1px solid #ccc;
      border-radius: 8px;
      

    }
    
  .carde-description {
    text-align: initial;
    padding: 20px;
  }
    .carde-body {
      display: flex;
      justify-content: center;
      align-items: center;
     
    }
    
    
    
    .row2 {
      display: flex;
      
    }
    
    .carde-image-container,
    .carde-video-container {
      flex: 1;
      padding: 10px; /* Adjust the spacing between the image and video */
    }
    
    .carde-image-container img ,
    .carde-video-container video {
      width: 100%;
      height: auto;
    }
    
    .cardd-content{
      border-radius: 8px;
    }
    
  @media only screen and (max-width: 768px) {

   
    .cardf, .carde, .cardc, .cardd, .cardff{
      flex-direction: column;
      align-items: center;
    }
    .row h5{
      text-align: justify;
     padding: 3px;
    }
    
    h3{
      justify-content: center;
      align-items: center;
      margin-right: 15%;
    }
    .cardff-title{
      text-align: center;
      justify-content: center;
    }
    .perfect{
      margin: 0px;
    }
    .vi{
      max-width: 100%;
    }
    .ime{
      max-width: "100%";
      min-width: "100%";
    }
  }  