*{
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
    padding: 0;
    top: 0;
    
} 
 .header{
    position: fixed;
    width: 100%;
    z-index: 9999;
    margin: initial;
} 

body{
margin: 0;
}


label.logo{
    line-height: 80px;
    padding: 0 2px;
    
}
.image{
    display: block;
    height: 79px;
    width: 100%;
    margin: 0%;
    
}

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    height: 79px;
    background-color: rgb(86, 181, 86);
}


.nav{
    display: flex;

}

.nav li{
    padding: 0 1rem;
    display: inline;
    text-decoration: none;
    margin-left: 40px ;
}

.nav li a{
    font-size: 1.2rem;
    font-weight: 500;
    display: flex;
    text-decoration: none;
    color: black;
    margin-right: 2rem;

}
 a:hover{
    transition: .3s;   
    border-radius: 5px;
}

.dropdown-menu{
    position: absolute;
    font-size: 1rem;
    background-color: white;
    width: 200px;
    z-index: 1;
    margin-top: 60px;
    margin-right: 150px;
    padding: 10px 10px;


}
.drop-items a{
    padding-bottom: 5px;
    border-bottom: 1px  solid black;
    padding-top: 2px;
}

.drop-items a:last-child{
    border-bottom: none;
}

.dropdown-menu a:hover{
    background-color: rgb(192, 236, 192);

}



.right-menu:hover .dropdown-menu{
    display:block;
}
    


/* @media(max-width: 952px){
    label.logo{
    font-size: 30px;
    padding-left: 50px;
}
nav ul li a{
    font-size: 16px;
}
}

@media(max-width: 858px){
    
    ul{
        position: fixed;
        width: 100%;
        height: 100vh;
        background: #2c3e50;
        top: 80px;
        left: 100%;
        text-align: center;
        transition: all .5s;
    }
    nav ul li{
        display: block;
        margin: 50px 0;
        line-height: 30px;
    }
    nav ul li a{
        font-size: 20px;
    }
    a:hover,a.active{
        background: none;
        color: #0082e6;
    }
     */
